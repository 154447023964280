@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#root {
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  display: flex;
  flex-flow: column;
  background-color: #e4e4e473;
  justify-content: space-between;
  inline-size: 100vw;
  min-block-size: 100vh;
}
